import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.pages = window.JAM.pages || {};

window.JAM.pages.getCustomer = (() => {
  const init = () => {
    const pageSelector = ".app-get-customer";

    const $page = $(pageSelector);
    if (!$page.length) {
      return;
    }

    JAM.components.common.showLoadersOnFormSubmit();
    JAM.components.fetchCustomerMsisdn.init();
  };

  return {
    init,
  };
})();

$(function () {
  window.JAM.pages.getCustomer.init();
});
