import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.components = window.JAM.components || {};

window.JAM.components.fetchCustomerMsisdn = (() => {
  let $pageData;
  let safaricomAccessToken;
  let msisdnInputSelector;
  let encryptedMsisdnInputSelector;
  let partnerId;
  let formSelector;
  let reloadButtonSelector;
  let reloadButtonContainerSelector;
  let trackVisitUrl;
  let autoRedirect;
  let autoRedirectDelay;
  let submitButtonContainerSelector;

  const init = () => {
    $pageData = $("#page-data");
    partnerId = $pageData.data("dot-partner-id");
    safaricomAccessToken = $pageData.data("safaricom-access-token");
    trackVisitUrl = $pageData.data("track-visit-url");
    autoRedirect = $pageData.data("auto-redirect");
    autoRedirectDelay = $pageData.data("auto-redirect-delay");
    formSelector = "#msisdn-form";
    msisdnInputSelector = "#msisdn-input";
    encryptedMsisdnInputSelector = "#encrypted-msisdn-input";
    reloadButtonSelector = "#reload-button";
    reloadButtonContainerSelector = "#reload-button-container";
    submitButtonContainerSelector = "#submit-button-container";

    const networkCode = $("#network-code-input").val();

    if (networkCode == "safaricom") {
      fetchSafaricomCustomerMsisdn();
    } else if (networkCode == "zong") {
      fetchZongCustomerMsisdn();
    }

    $(reloadButtonSelector).on("click", function () {
      location.reload();
    });
  };

  /**
   * Fetches the customer's MSISDN using the Safaricom network.
   *
   * Makes a GET request to the Safaricom HE API to retrieve a masked MSISDN
   * for the customer using the provided access token. On a successful response,
   * populates the MSISDN input field and submits the form. Displays an error
   * message if the request fails or the MSISDN is not retrieved.
   */

  const fetchSafaricomCustomerMsisdn = () => {
    $(submitButtonContainerSelector).hide();
    JAM.components.common.showLoader();

    const API_URL = `${JAM.vars.SAFARICOM_HE_API_URL}/partner/api/v2/fetchMaskedMsisdn`;

    try {
      fetch(API_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${safaricomAccessToken}`,
          "Content-Type": "application/json",
          "X-App": "he-partner",
          "X-MessageID": crypto.randomUUID(),
          "X-Source-System": "he-partner",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          // Handle other errors
          return response.text().then((text) => {
            throw Error(text);
          });
        })
        .then((data) => {
          const msisdn = data.ServiceResponse.ResponseBody.Response.Msisdn;
          const responseCode = data.ServiceResponse.ResponseHeader.ResponseCode;

          if (responseCode == 200 && msisdn) {
            $(msisdnInputSelector).val(msisdn);
            JAM.components.common.hideLoader();
            $(submitButtonContainerSelector).show();
            handleAutoRedirect();
          } else {
            handleSafaricomFetchMsisdnFailure(JSON.stringify(data));
          }
        })
        .catch((error) => {
          handleSafaricomFetchMsisdnFailure(error);
        });
    } catch (error) {
      window.JAM.components.common.showFlashMessage(
        "An error occurred when fetching your mobile number" + error,
        "success"
      );
    }
  };

  /**
   * Handles failure to fetch customer MSISDN from Safaricom.
   *
   * If the request to fetch the customer MSISDN from Safaricom fails,
   * this function displays an error message and shows the reload button.
   */
  const handleSafaricomFetchMsisdnFailure = (message = "") => {
    $(reloadButtonContainerSelector).removeClass("d-none");

    JAM.components.common.hideLoader();

    window.JAM.components.common.showFlashMessage(
      "Please switch to Safaricom network and try again. " + message,
      "error"
    );
  };

  /**
   * Fetches the customer's MSISDN using the Zong network.
   *
   * Makes a GET request to the Zong HTTP API to retrieve an encrypted MSISDN
   * for the customer using the provided partner ID. On a successful response,
   * calls the handleZongFetchMsisdnSuccess function with the retrieved MSISDN.
   * Calls the handleZongFetchMsisdnFailure function if the request fails or the
   * MSISDN is not retrieved.
   */
  const fetchZongCustomerMsisdn = () => {
    const xhr = new XMLHttpRequest();

    xhr.open(
      "GET",
      `${JAM.vars.DOT_HTTP_API_URL}/appgw/GetPartnerHEMSISDN?partnerId=${partnerId}`,
      true
    );

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          const responseJson = JSON.parse(xhr.responseText);
          const responseCode = responseJson["errorCode"];

          if (responseCode == 0) {
            const encryptedMsisdn = responseJson["encMSISDN"];

            if (encryptedMsisdn) {
              handleZongFetchMsisdnSuccess(encryptedMsisdn);
            } else {
              handleZongFetchMsisdnFailure();
            }
          } else {
            handleZongFetchMsisdnFailure();
          }
        } else {
          // Oh no! There has been an error with the request!
          handleZongFetchMsisdnFailure();
        }
      }
    };

    xhr.send();
  };

  /**
   * Handles a successful response from the Zong HTTP API to fetch the customer MSISDN.
   *
   * @param {string} encryptedMsisdn The encrypted MSISDN received from the Zong HTTP API.
   *
   * Populates the encrypted MSISDN input field and submits the form. Tracks the visit
   * using an AJAX request to the server.
   */
  const handleZongFetchMsisdnSuccess = (encryptedMsisdn) => {
    $(encryptedMsisdnInputSelector).val(encryptedMsisdn);
    $(msisdnInputSelector).removeAttr("required");

    const data = {
      encrypted_msisdn: encryptedMsisdn,
    };

    const onComplete = () => {
      handleAutoRedirect();
      JAM.components.common.hideLoader();
    };

    trackVisit(data, onComplete);
  };

  /**
   * Handles failure to fetch customer MSISDN from Zong.
   *
   * If the request to fetch the customer MSISDN from Zong fails,
   * this function displays an error message, shows the MSISDN input field,
   * and hides the loader.
   */
  const handleZongFetchMsisdnFailure = () => {
    $(msisdnInputSelector).removeClass("d-none");

    JAM.components.common.hideLoader();

    window.JAM.components.common.showFlashMessage(
      "Please enter your mobile number",
      "success"
    );
  };

  /**
   * Sends a POST request to track the customer's visit.
   *
   * @param {Object} data - The data to be sent with the request, including optional token.
   * @param {Function} onComplete - Callback function to execute upon completion of the AJAX request.
   *
   * Retrieves the CSRF token from the document and includes it in the request headers.
   * If a token input is present in the DOM, it is added to the data object before sending.
   */

  const trackVisit = (data, onComplete) => {
    let token = document.getElementsByName("csrf-token")[0].content;

    if ($("#token-input").length) {
      data = { ...data, token: $("#token-input").val() };
    }

    $.ajax({
      url: trackVisitUrl,
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
      },
      data: data,
      complete: onComplete,
    });
  };

  /**
   * Handles automatic redirection to the consent page.
   *
   * If auto redirection is enabled, this function will delay for a specified
   * amount of time (or a default of 1000 milliseconds) before showing a loader
   * and submitting the form.
   */
  const handleAutoRedirect = () => {
    if (autoRedirect == "true") {
      setTimeout(() => {
        JAM.components.common.showLoader();
        submitForm();
      }, autoRedirectDelay || 1000);
    }
  };

  /**
   * Simulates a click event on the first submit button in the form.
   *
   * This function is used to programmatically submit the form when the user
   * has been redirected to the consent page after their MSISDN has been automatically
   * fetched from Zong.
   */
  const submitForm = () => {
    $(formSelector).find("input[type=submit").first().trigger("click");
  };

  return {
    init,
  };
})();
